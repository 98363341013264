// Generated by Framer (4c611f3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CeChT3d1P", "LzH4HRVVw"];

const variantClassNames = {CeChT3d1P: "framer-v-1mi58vh", LzH4HRVVw: "framer-v-11702as"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 2, ease: [0, 0, 1, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "CeChT3d1P", "Variant 2": "LzH4HRVVw"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "CeChT3d1P"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CeChT3d1P", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearfzmf19 = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 1000)
})

useOnVariantChange(baseVariant, {default: onAppearfzmf19})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Hbpoo", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1mi58vh", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"CeChT3d1P"} ref={ref} style={{...style}} {...addPropertyOverrides({LzH4HRVVw: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1d43shz"} layoutDependency={layoutDependency} layoutId={"SR4pvXtRF"} style={{backgroundColor: "rgb(26, 255, 117)", borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, boxShadow: "0px 0px 8px 0px rgba(26, 255, 117, 0.4)", opacity: 1}} variants={{LzH4HRVVw: {opacity: 0.3}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Hbpoo [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Hbpoo .framer-1q4e4ut { display: block; }", ".framer-Hbpoo .framer-1mi58vh { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 12px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 12px; }", ".framer-Hbpoo .framer-1d43shz { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 8px); overflow: visible; position: relative; width: 8px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Hbpoo .framer-1mi58vh { gap: 0px; } .framer-Hbpoo .framer-1mi58vh > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-Hbpoo .framer-1mi58vh > :first-child { margin-left: 0px; } .framer-Hbpoo .framer-1mi58vh > :last-child { margin-right: 0px; } }", ".framer-Hbpoo.framer-v-11702as .framer-1mi58vh { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 12px); }", ".framer-Hbpoo.framer-v-11702as .framer-1d43shz { height: var(--framer-aspect-ratio-supported, 4px); width: 4px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 12
 * @framerIntrinsicWidth 12
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LzH4HRVVw":{"layout":["fixed","fixed"]}}}
 */
const FramerrQ6eqfFN0: React.ComponentType<Props> = withCSS(Component, css, "framer-Hbpoo") as typeof Component;
export default FramerrQ6eqfFN0;

FramerrQ6eqfFN0.displayName = "Elements/Activity Indicator";

FramerrQ6eqfFN0.defaultProps = {height: 12, width: 12};

addPropertyControls(FramerrQ6eqfFN0, {variant: {options: ["CeChT3d1P", "LzH4HRVVw"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerrQ6eqfFN0, [])